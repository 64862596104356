
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.employee-form-grid {
    padding: 25px 0px;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(1,50px);
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    color: red;
}

.employee-form-grid div input {
    max-width: 280px;
}

.headLogo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.btnSearch {
    background-color: #910039;
    color: #FFFFFF;
    padding: 8px 20px;
    border: none;
}

.btnCreate {
    background-color: #910039;
    color: #FFFFFF;
    padding: 8px 20px;
    border: none;
}

.btnCreates {
    background-color: #910039;
    color: #FFFFFF;
    padding: 8px 19px;
    border: none;
}
.action-btn {
    display: flex;
    justify-content: space-evenly;
}

.action-btn a {
    background-color: transparent;
}

.action-btn .edit-btn {
    color: #910039;
}

.action-btn .delete-btn {
    color: #910039;
}

.dropzone {
    border: 1px dashed#910039;
    padding-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}
.dropzone p {
    cursor: pointer;
    padding: 15px;
}
.dropzone span{
    color:green;
    margin-bottom: 20px;
}

.errorsmall {
    color:red;
    float:left
}

.mobileInput {
    width: 100%;
    border: 1px solid #c4c4c4!important; 
    margin: 0;
    display: block;
    padding: 12px 14px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    -webkit-tap-highlight-color: transparent;
    border-top-right-radius: 5px!important;
    border-bottom-right-radius: 5px!important;
    border-radius: 0;
}

.phone-input input[type="tel"] {
    border: 1px solid #bdbdbd; /* Customize border */
    padding: 10px; /* Customize padding */
    border-radius: 4px; /* Customize border radius */
    width: 100%; /* Make it full width */
    background-color: #fff; /* Background color */
    color: #333; /* Text color */
    font-size: 16px; /* Font size */
  }
  
  /* Style the PhoneInput container */
  .phone-input {
    width: 100%; /* Make it full width */
  }
