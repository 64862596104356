@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

body{
  color: #151515!important;
}
.dropdown:hover .dropdown-menu{
  display:block;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
}
p{
  font-size: 18px;
  line-height:2.2rem;
}
ul, ol{
  font-size: 16px;
  line-height:2.2rem;
}
.navbar{
  background-color: #fff;
}
.menu_active{
font-weight:bold;
border-bottom: 1px solid #910039 ;
}

.nav-link{
  margin-left:1rem;
  color: #151515!important;
}
.nav-item{
  font-size: 18px;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  font-weight:bold;
  border-bottom: 1px solid #910039 ;
}

.nav-link-login{
 border-radius: .3rem;
 border: 1px solid #910039;
 padding: .4rem 2rem!important; 
}
.nav-link-login:hover{
  background-color: #910039;
  color: #fff!important;
 }
.position-absolute{
  position: absolute;
  top:35%;
  right:0;
}
.banner-content{
  padding: 2rem 3rem!important;
  color:#fff;
  background-color:#910039;
  border-left: .5rem solid rgba(255, 255, 255,.5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}

.banner-content p{
  width: 95%;
}
.h1, h2, h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 600!important;
}

.three-section{
  padding-bottom:3rem;
}
.paragraph-text{
  margin-top: 1rem;
  width: 100%;
  line-height: 2.35rem;
}
.our-stories{
  padding-top: 20px;
  padding-bottom: 30px;
}
.stories{
  font-size: 28px;
  padding-bottom:15px ;
}

.about-section{
  margin-top: 35px;
  margin-bottom: 35px;
}
.member{
  font-size: 24px;
}
.member-dest{
  margin-bottom: 35px;
}
.btn-default{
margin-top:1rem;
background-color:  #910039 !important;
color:#fff!important;
font-size: 1.4rem !important;
font-weight: 500 !important;
border-radius: 0 !important;
transform: skew(-17.7deg);
padding: .375rem 1.4rem!important;
}
.btn-default:hover{
  background-color:  #333 !important;
}
body .btn-default span{
  display: block;
  transform: skew(17.7deg);
}
.blog-style{
  border: 1px solid #707070;
  padding-right:2rem;
}

.blog-style p{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-style h4{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-style a{
text-decoration: none;
color:#910039;
font-weight:bold;
}
.footer-design{
  background: rgb(216,234,245);
  background: linear-gradient(90deg, rgba(216,234,245,1) 0%, rgba(252,233,240,1) 100%);
}
.footer-container{
margin-top: 25px;
margin-bottom: 20px;
}
.email{
  font-size: 16px;
}
.footer-text{
  font-size: 0.90rem;
}
.icon-margin{
  margin-right: 1rem;
}
.footer-design h5{
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.copyright{
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size:.7rem;
  color:#707070;
}
.copyright hr{
  width:10%;
}
.ps-8{
  font-size: 35px;
  padding-right:6rem!important;
}
.contactsection{
  margin-top: 60px;
  margin-bottom: 55px;
}
.contactus{
  font-size: 28px;
  padding-bottom: 2rem;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #910039!important;
}

.footer-link {
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline */
}

.footer-link:hover {
  text-decoration: underline; /* Add underline on hover */
}

